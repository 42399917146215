.Header {
    background-color: #000;
    color: #fff;
    height: 80px;
}
.header {
    margin: 0 120px;
    height:80px;
}
.header-inside {
    display: flex;
    justify-content: space-between;
    vertical-align:middle;
}
.left , .right {
    display: flex;
}
.logoBlock {
    display: flex;
    vertical-align:middle;
    margin-right: 30px;
}
.items {
    display: flex;
    vertical-align: middle;
    height: 80px;
}
.menuItem {
    padding: 13px;
}
.logo{
    max-width: 200px;
    margin: 10px 0px;
    vertical-align:middle;
}
.searchBlock , .buttonBlock {
    display: flex;
}
.search {
    max-width: 20px;
    max-height: 20px;
    padding:30px 20px 30px 0px;
    vertical-align: middle; 
}
.button , .button2 {
    max-height: 50px;
    margin: 15px 5px;
    padding: 10px 20px;
    vertical-align: middle;
    font-family: "Jockey One", sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 25px;
}
.button {
    border: 2px solid #F4CA4F;
    color: #fff;
    border-radius: 5px;
    background-color: #000;
}
.button2 {
    background-color: #F4CA4F;
    border: 0;
    border-radius: 5px;
}
ul {
    text-decoration: none;
    list-style: none;
}
li {
    display: inline-block;
}